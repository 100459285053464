import {
  ampli,
  CuriosityItemClickedProperties as AmpliCuriosityItemClickedProperties,
  GetMatchedClickedProperties as AmpliGetMatchedClickedProperties,
  MessageSentProperties as AmpliMessageSentProperties,
  PillarItemClickedProperties as AmpliPillarItemClickedProperties,
  ProfileVideoErrorProperties as AmpliProfileVideoErrorProperties,
  ProfileVideoPlayInitiatedProperties as AmpliProfileVideoPlayInitiatedProperties,
  ProfileVideoStartedProperties as AmpliProfileVideoStartedProperties,
  ProfileViewedProperties as AmpliProfileViewedProperties,
  RemovedFromFavoritesProperties as AmpliRemovedFromFavoritesProperties,
  SavedToFavoritesProperties as AmpliSavedToFavoritesProperties,
  TherapistInquiryInitiatedProperties as AmpliTherapistInquiryInitiatedProperties,
  TherapistInquirySubmittedProperties as AmpliTherapistInquirySubmittedProperties,
} from "@/analytics/ampli";
import * as api from "@/api";
import { ReadonlyURLSearchParams } from "next/navigation";
import { SourceAction } from "./sourceAction";
import { SourceFlow } from "./sourceFlow";
import { SourcePage } from "./sourcePage";
import { pushDataLayerEvent } from "@/analytics/analytics.datalayer";

type DirectoryPageViewedProps = {
  source_page:
    | "main_page"
    | "category_page"
    | "city_page"
    | "district_page"
    | "language_page"
    | "eap_page"
    | "eap_company_page"
    | "online_therapy_page";
  source_page_slug?: string;
};
function directoryPageViewed(props: DirectoryPageViewedProps) {
  ampli.directoryPageViewed(props);
}

type DirectorySearchPerformanceProps = {
  therapists_listing_page?: number;
  therapists_listing_rank?: string;
  therapists_listing_ranker_version?: string;
  therapists_listing_total_size?: number;
};

type ProfileViewedProps = Omit<
  AmpliProfileViewedProperties,
  "source_page" | keyof DirectorySearchPerformanceProps
>;

function profileViewed(
  props: ProfileViewedProps,
  query?: ReadonlyURLSearchParams,
) {
  // soft conversion for ads
  pushDataLayerEvent("profile_viewed");

  ampli.profileViewed({
    source_page: "therapist_profile_page",
    ...props,
    ...extractSearchPerformanceData(query),
  });
}

type ProfileVideoPlayInitiatedProperties = Omit<
  AmpliProfileVideoPlayInitiatedProperties,
  "source_page"
> & {
  source_page: SourcePage;
};

function profileVideoPlayInitiated(props: ProfileVideoPlayInitiatedProperties) {
  ampli.profileVideoPlayInitiated(props);
}

type ProfileVideoStartedProperties = Omit<
  AmpliProfileVideoStartedProperties,
  "source_page"
> & {
  source_page: SourcePage;
};

function profileVideoStarted(props: ProfileVideoStartedProperties) {
  ampli.profileVideoStarted(props);
}

type ProfileVideoErrorProperties = Omit<
  AmpliProfileVideoErrorProperties,
  "source_page"
> & {
  source_page: SourcePage;
};

function profileVideoError(props: ProfileVideoErrorProperties) {
  ampli.profileVideoError(props);
}

type TherapistInquiryInitiatedProps = Omit<
  AmpliTherapistInquiryInitiatedProperties,
  | "source_page"
  | "source_flow"
  | "source_action"
  | keyof DirectorySearchPerformanceProps
> & {
  source_page: SourcePage;
  source_flow: SourceFlow;
  source_action: SourceAction;
};

function therapistInquiryInitiated(
  props: TherapistInquiryInitiatedProps,
  query?: ReadonlyURLSearchParams,
) {
  // soft conversion for ads
  pushDataLayerEvent("therapist_inquiry_initiated");

  ampli.therapistInquiryInitiated({
    ...props,
    ...extractSearchPerformanceData(query),
  });
}

type TherapistInquirySubmittedProps = Omit<
  AmpliTherapistInquirySubmittedProperties,
  "source_page" | "source_flow"
> & {
  source_page: SourcePage;
  source_flow: SourceFlow;
};

function therapistInquirySubmitted(props: TherapistInquirySubmittedProps) {
  ampli.therapistInquirySubmitted(props);
}

type MessageSentProps = Omit<AmpliMessageSentProperties, "source_page">;

function messageSent(props: MessageSentProps) {
  ampli.messageSent({
    source_page: "therapist_profile_page",
    ...props,
  });
}

type SavedToFavoritesProperties = Omit<
  AmpliSavedToFavoritesProperties,
  "source_page"
> & {
  source_page: SourcePage;
};

function savedToFavorites(props: SavedToFavoritesProperties) {
  ampli.savedToFavorites(props);
}

type RemovedFromFavoritesProperties = Omit<
  AmpliRemovedFromFavoritesProperties,
  "source_page"
> & {
  source_page: SourcePage;
};

function removedFromFavorites(props: RemovedFromFavoritesProperties) {
  ampli.removedFromFavorites(props);
}

function therapistsListingPresented(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams,
  result: api.search.SearchResponseResult | null,
): void {
  ampli.therapistsListingPresented({
    therapists_listing_ranker_version: result?.ranker_version,
    therapists_listing_total_size: result?.total_matches,
    // For consistency with the rails app, the source page is alwasy "landing_page".
    // We are taking a conservative approach to be able to compare tracking before and after
    // migration. If necessary, after migration, we can add "city_page", "category_page",
    // "language_page".
    source_page: "landing_page",
    // source_action: <- we are not tracking source_action since it is not used currently and
    // hard to track
    approaches: approaches(options, query),
    specialities: specialities(options, query),
    keywords: query?.filters?.keywords?.split(","),
    filter_genders: genders(options, query),
    filter_identities: identities(options, query),
    filter_insurances: insurances(options, query),
    filter_languages: languages(options, query),
    filter_location: locations(options, query),
    filter_formats: formats(options, query),
    // this can be deprecated once we launch formats officially
    filter_is_available_online:
      query?.filters?.formats?.includes("online") || false,
    filter_is_eap: query?.filters?.isEap || false,
  });
}

function locations(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.locations.all, query?.filters?.locations);
}

function formats(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.formats.all, query?.filters?.formats);
}

function languages(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.languages.all, query?.filters?.languages);
}

function insurances(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.insurances.all, query?.filters?.insurances);
}

function identities(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.identities.all, query?.filters?.identities);
}

function genders(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.genders.all, query?.filters?.genders);
}

function specialities(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.specialities.all, query?.filters?.specialities);
}

function approaches(
  options: api.search.SearchFiltersOptions,
  query: api.search.SearchParams | null,
) {
  return analyticsIds(options.approaches.all, query?.filters?.approaches);
}

function analyticsIds(
  options: api.search.SearchOption[],
  selectedIds: string[] | undefined,
) {
  if (selectedIds) {
    return options
      .filter((o) => selectedIds.includes(o.search_filter_id))
      .map((o) => o.analytics_id);
  }
  return [];
}

type SearchTrackingData = {
  therapists_listing_page?: number;
  therapists_listing_rank?: string;
  therapists_listing_ranker_version?: string;
  therapists_listing_total_size?: number;
};

function extractSearchPerformanceData(
  searchParams?: ReadonlyURLSearchParams,
): SearchTrackingData | undefined {
  if (!searchParams) {
    return undefined;
  }
  return {
    therapists_listing_rank: searchParams.get("lr") as string,
    therapists_listing_ranker_version: searchParams.get("lrv") as string,
    therapists_listing_page: searchParams.has("lpi")
      ? Number(searchParams.get("lpi"))
      : undefined,
    therapists_listing_total_size: searchParams.has("lts")
      ? Number(searchParams.get("lts"))
      : undefined,
  };
}

type GetMatchedClickedSource =
  | "search_results_card"
  | "filter_cta"
  | "pillars_cta"
  | "no_search_results"
  | "after_paging_inline_callout";
type GetMatchedClickedProperties = Omit<
  AmpliGetMatchedClickedProperties,
  "source"
> & {
  source: GetMatchedClickedSource;
};

function getMatchedClicked(props: GetMatchedClickedProperties) {
  ampli.getMatchedClicked(props);
}

type CuriosityItemClickedProperties = Omit<
  AmpliCuriosityItemClickedProperties,
  "source_page"
> & {
  source_page: SourcePage;
};
function curiosityItemClicked(props: CuriosityItemClickedProperties) {
  ampli.curiosityItemClicked(props);
}

type PillarItemClickedProperties = Omit<
  AmpliPillarItemClickedProperties,
  "source_page"
> & {
  source_page: SourcePage;
};
function pillarItemClicked(props: PillarItemClickedProperties) {
  ampli.pillarItemClicked(props);
}

export {
  directoryPageViewed,
  messageSent,
  profileVideoError,
  profileVideoPlayInitiated,
  profileVideoStarted,
  profileViewed,
  removedFromFavorites,
  savedToFavorites,
  therapistInquiryInitiated,
  therapistInquirySubmitted,
  therapistsListingPresented,
  getMatchedClicked,
  curiosityItemClicked,
  pillarItemClicked,
};

export type { DirectoryPageViewedProps, GetMatchedClickedSource };
