/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull nextjs'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 10
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/implementation/nextjs)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'staging' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '8f7f4f717a8b80e38c3d37f01bb5b78c',
  staging: '9c5f2bbf14cb1a6abbef9ad03e71af9c',
  development: 'e0691d18d481a9839655c7c6b05335b3'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '10',
    branch: 'main',
    source: 'nextjs',
    versionId: '973f5e00-b1bf-488b-9a99-2e81a3ae7066'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gbraid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_id?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  initial_wbraid?: any;
  kind?: string;
}

export interface AddToFavoritesClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface AuthPopupClosedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  duration: number;
  error?: string;
  success: boolean;
}

export interface AuthPromptViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_action?: string;
  source_flow: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface AuthStartedProperties {
  auth_kind?: string;
  has_therapist_referral?: boolean;
}

export interface CommunityCardClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  source_page_slug?: string;
}

export interface CreateAccountFinishedProperties {
  has_contact_intent?: any;
}

export interface CreateAccountStartedProperties {
  has_therapist_referral?: boolean;
}

export interface CuriosityItemClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | couples_therapy, coaching, psychadelic_integration, crisis_response, get_matched, adhd, lgbtqi, jungian, expats, psychoanalysis, cbt |
   */
  curiosity_item:
    | "couples_therapy"
    | "coaching"
    | "psychadelic_integration"
    | "crisis_response"
    | "get_matched"
    | "adhd"
    | "lgbtqi"
    | "jungian"
    | "expats"
    | "psychoanalysis"
    | "cbt";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
}

export interface DirectoryPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  source_page_slug?: string;
}

export interface FeaturedTherapistVideoViewProfileClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  index: number;
  therapist_user_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | bottom_overlay, finished_main_cta |
   */
  therapist_video_click_source: "bottom_overlay" | "finished_main_cta";
}

export interface FeaturedTherapistVideoViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  index: number;
  therapist_user_id: string;
}

export interface GetMatchedClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source: string;
}

export interface MatchedProfileClickedProperties {
  therapist_user_id: string;
  was_auto_match: boolean;
}

export interface MatchingPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  auto_match_results_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  manual_match_results_count: number;
}

export interface MessageSentProperties {
  conversation_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  conversation_messages_total?: number;
  has_active_video: boolean;
  is_a_favorite: boolean;
  is_first_message?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_display_city_slug: string;
  therapist_user_id: string;
}

export interface PillarItemClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | get_matched, browse_all_therapists, about_us, local_know_how |
   */
  pillar_item: "get_matched" | "browse_all_therapists" | "about_us" | "local_know_how";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  source_page_slug?: string;
}

export interface ProfileVideoErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface ProfileVideoPlayInitiatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface ProfileVideoStartedProperties {
  error_occurred_before_start: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  load_time_ms: number;
  pause_occurred_before_start: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface ProfileViewedProperties {
  has_active_video: boolean;
  is_available_for_clients: boolean;
  is_limited_profile: boolean;
  is_showing_calendar_availability: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_display_city_slug: string;
  therapist_user_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  therapists_listing_page?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  therapists_listing_rank?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  therapists_listing_ranker_version?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  therapists_listing_total_size?: number;
}

export interface RemovedFromFavoritesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface SavedToFavoritesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface TherapistInquiryInitiatedProperties {
  has_active_video: boolean;
  is_a_favorite: boolean;
  source_action?: string;
  source_flow: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  therapists_listing_page?: number;
  therapists_listing_rank?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  therapists_listing_ranker_version?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  therapists_listing_total_size?: number;
}

export interface TherapistInquirySubmittedProperties {
  appointment_selected: boolean;
  appointments_available: boolean;
  has_active_video: boolean;
  is_a_favorite: boolean;
  source_flow: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page: string;
  therapist_user_id: string;
}

export interface TherapistsListingPresentedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  approaches?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_formats?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_genders?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_identities?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_insurances?: string[];
  filter_is_available_online?: boolean;
  filter_is_available_this_week?: boolean;
  filter_is_eap?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_languages?: string[];
  filter_location?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  keywords?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_action?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source_page?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  specialities?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  therapists_listing_ranker_version?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  therapists_listing_total_size?: number;
}

export interface VideoCallRatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  rating: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddToFavoritesClicked implements BaseEvent {
  event_type = 'Add To Favorites Clicked';

  constructor(
    public event_properties: AddToFavoritesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthPopupClosed implements BaseEvent {
  event_type = 'Auth Popup Closed';

  constructor(
    public event_properties: AuthPopupClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthPromptViewed implements BaseEvent {
  event_type = 'Auth Prompt Viewed';

  constructor(
    public event_properties: AuthPromptViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthStarted implements BaseEvent {
  event_type = 'Auth Started';

  constructor(
    public event_properties?: AuthStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CommunityCardClicked implements BaseEvent {
  event_type = 'Community Card Clicked';

  constructor(
    public event_properties: CommunityCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateAccountFinished implements BaseEvent {
  event_type = 'Create Account Finished';

  constructor(
    public event_properties?: CreateAccountFinishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateAccountStarted implements BaseEvent {
  event_type = 'Create Account Started';

  constructor(
    public event_properties?: CreateAccountStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CuriosityItemClicked implements BaseEvent {
  event_type = 'Curiosity Item Clicked';

  constructor(
    public event_properties: CuriosityItemClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DirectoryPageViewed implements BaseEvent {
  event_type = 'Directory Page Viewed';

  constructor(
    public event_properties: DirectoryPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FavoritesViewed implements BaseEvent {
  event_type = 'Favorites Viewed';
}

export class FeaturedTherapistVideoViewProfileClicked implements BaseEvent {
  event_type = 'Featured Therapist Video View Profile Clicked';

  constructor(
    public event_properties: FeaturedTherapistVideoViewProfileClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeaturedTherapistVideoViewed implements BaseEvent {
  event_type = 'Featured Therapist Video Viewed';

  constructor(
    public event_properties: FeaturedTherapistVideoViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GetMatchedClicked implements BaseEvent {
  event_type = 'Get Matched Clicked';

  constructor(
    public event_properties: GetMatchedClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MatchedProfileClicked implements BaseEvent {
  event_type = 'Matched Profile Clicked';

  constructor(
    public event_properties: MatchedProfileClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MatchingPageViewed implements BaseEvent {
  event_type = 'Matching Page Viewed';

  constructor(
    public event_properties: MatchingPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MatchingSurveyStarted implements BaseEvent {
  event_type = 'Matching Survey Started';
}

export class MessageSent implements BaseEvent {
  event_type = 'Message Sent';

  constructor(
    public event_properties: MessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PillarItemClicked implements BaseEvent {
  event_type = 'Pillar Item Clicked';

  constructor(
    public event_properties: PillarItemClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileVideoError implements BaseEvent {
  event_type = 'Profile Video Error';

  constructor(
    public event_properties: ProfileVideoErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileVideoPlayInitiated implements BaseEvent {
  event_type = 'Profile Video Play Initiated';

  constructor(
    public event_properties: ProfileVideoPlayInitiatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileVideoStarted implements BaseEvent {
  event_type = 'Profile Video Started';

  constructor(
    public event_properties: ProfileVideoStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileViewed implements BaseEvent {
  event_type = 'Profile Viewed';

  constructor(
    public event_properties: ProfileViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegisterAccountFinished implements BaseEvent {
  event_type = 'Register Account Finished';
}

export class RegisterAccountStarted implements BaseEvent {
  event_type = 'Register Account Started';
}

export class RemovedFromFavorites implements BaseEvent {
  event_type = 'Removed From Favorites';

  constructor(
    public event_properties: RemovedFromFavoritesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SavedToFavorites implements BaseEvent {
  event_type = 'Saved To Favorites';

  constructor(
    public event_properties: SavedToFavoritesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TherapistInquiryInitiated implements BaseEvent {
  event_type = 'Therapist Inquiry Initiated';

  constructor(
    public event_properties: TherapistInquiryInitiatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TherapistInquirySubmitted implements BaseEvent {
  event_type = 'Therapist Inquiry Submitted';

  constructor(
    public event_properties: TherapistInquirySubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TherapistsListingPresented implements BaseEvent {
  event_type = 'Therapists Listing Presented';

  constructor(
    public event_properties?: TherapistsListingPresentedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VideoCallRated implements BaseEvent {
  event_type = 'Video Call Rated';

  constructor(
    public event_properties: VideoCallRatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Add To Favorites Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Add%20To%20Favorites%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  addToFavoritesClicked(
    properties: AddToFavoritesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToFavoritesClicked(properties), options);
  }

  /**
   * Auth Popup Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Auth%20Popup%20Closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. duration)
   * @param options Amplitude event options.
   */
  authPopupClosed(
    properties: AuthPopupClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthPopupClosed(properties), options);
  }

  /**
   * Auth Prompt Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Auth%20Prompt%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_action)
   * @param options Amplitude event options.
   */
  authPromptViewed(
    properties: AuthPromptViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthPromptViewed(properties), options);
  }

  /**
   * Auth Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Auth%20Started)
   *
   * Owner: Guillaume Lung
   *
   * @param properties The event's properties (e.g. auth_kind)
   * @param options Amplitude event options.
   */
  authStarted(
    properties?: AuthStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthStarted(properties), options);
  }

  /**
   * Community Card Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Community%20Card%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  communityCardClicked(
    properties: CommunityCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CommunityCardClicked(properties), options);
  }

  /**
   * Create Account Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Create%20Account%20Finished)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. has_contact_intent)
   * @param options Amplitude event options.
   */
  createAccountFinished(
    properties?: CreateAccountFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateAccountFinished(properties), options);
  }

  /**
   * Create Account Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Create%20Account%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. has_therapist_referral)
   * @param options Amplitude event options.
   */
  createAccountStarted(
    properties?: CreateAccountStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateAccountStarted(properties), options);
  }

  /**
   * Curiosity Item Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Curiosity%20Item%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. curiosity_item)
   * @param options Amplitude event options.
   */
  curiosityItemClicked(
    properties: CuriosityItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CuriosityItemClicked(properties), options);
  }

  /**
   * Directory Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Directory%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  directoryPageViewed(
    properties: DirectoryPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DirectoryPageViewed(properties), options);
  }

  /**
   * Favorites Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Favorites%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  favoritesViewed(
    options?: EventOptions,
  ) {
    return this.track(new FavoritesViewed(), options);
  }

  /**
   * Featured Therapist Video View Profile Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Featured%20Therapist%20Video%20View%20Profile%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. index)
   * @param options Amplitude event options.
   */
  featuredTherapistVideoViewProfileClicked(
    properties: FeaturedTherapistVideoViewProfileClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeaturedTherapistVideoViewProfileClicked(properties), options);
  }

  /**
   * Featured Therapist Video Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Featured%20Therapist%20Video%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. index)
   * @param options Amplitude event options.
   */
  featuredTherapistVideoViewed(
    properties: FeaturedTherapistVideoViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeaturedTherapistVideoViewed(properties), options);
  }

  /**
   * Get Matched Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Get%20Matched%20Clicked)
   *
   * Owner: Jon Schmidt
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  getMatchedClicked(
    properties: GetMatchedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GetMatchedClicked(properties), options);
  }

  /**
   * Matched Profile Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Matched%20Profile%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. therapist_user_id)
   * @param options Amplitude event options.
   */
  matchedProfileClicked(
    properties: MatchedProfileClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MatchedProfileClicked(properties), options);
  }

  /**
   * Matching Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Matching%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. auto_match_results_count)
   * @param options Amplitude event options.
   */
  matchingPageViewed(
    properties: MatchingPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MatchingPageViewed(properties), options);
  }

  /**
   * Matching Survey Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Matching%20Survey%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  matchingSurveyStarted(
    options?: EventOptions,
  ) {
    return this.track(new MatchingSurveyStarted(), options);
  }

  /**
   * Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Message%20Sent)
   *
   * Owner: Guillaume Lung
   *
   * @param properties The event's properties (e.g. conversation_id)
   * @param options Amplitude event options.
   */
  messageSent(
    properties: MessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSent(properties), options);
  }

  /**
   * Pillar Item Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Pillar%20Item%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pillar_item)
   * @param options Amplitude event options.
   */
  pillarItemClicked(
    properties: PillarItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PillarItemClicked(properties), options);
  }

  /**
   * Profile Video Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Profile%20Video%20Error)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  profileVideoError(
    properties: ProfileVideoErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileVideoError(properties), options);
  }

  /**
   * Profile Video Play Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Profile%20Video%20Play%20Initiated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  profileVideoPlayInitiated(
    properties: ProfileVideoPlayInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileVideoPlayInitiated(properties), options);
  }

  /**
   * Profile Video Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Profile%20Video%20Started)
   *
   * Triggered when a profile video actually starts playing
   *
   * @param properties The event's properties (e.g. error_occurred_before_start)
   * @param options Amplitude event options.
   */
  profileVideoStarted(
    properties: ProfileVideoStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileVideoStarted(properties), options);
  }

  /**
   * Profile Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Profile%20Viewed)
   *
   * Owner: Jon Schmidt
   *
   * @param properties The event's properties (e.g. has_active_video)
   * @param options Amplitude event options.
   */
  profileViewed(
    properties: ProfileViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileViewed(properties), options);
  }

  /**
   * Register Account Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Register%20Account%20Finished)
   *
   * Owner: Guillaume Lung
   *
   * @param options Amplitude event options.
   */
  registerAccountFinished(
    options?: EventOptions,
  ) {
    return this.track(new RegisterAccountFinished(), options);
  }

  /**
   * Register Account Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Register%20Account%20Started)
   *
   * Owner: Guillaume Lung
   *
   * @param options Amplitude event options.
   */
  registerAccountStarted(
    options?: EventOptions,
  ) {
    return this.track(new RegisterAccountStarted(), options);
  }

  /**
   * Removed From Favorites
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Removed%20From%20Favorites)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  removedFromFavorites(
    properties: RemovedFromFavoritesProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemovedFromFavorites(properties), options);
  }

  /**
   * Saved To Favorites
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Saved%20To%20Favorites)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source_page)
   * @param options Amplitude event options.
   */
  savedToFavorites(
    properties: SavedToFavoritesProperties,
    options?: EventOptions,
  ) {
    return this.track(new SavedToFavorites(properties), options);
  }

  /**
   * Therapist Inquiry Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Therapist%20Inquiry%20Initiated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. has_active_video)
   * @param options Amplitude event options.
   */
  therapistInquiryInitiated(
    properties: TherapistInquiryInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TherapistInquiryInitiated(properties), options);
  }

  /**
   * Therapist Inquiry Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Therapist%20Inquiry%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appointment_selected)
   * @param options Amplitude event options.
   */
  therapistInquirySubmitted(
    properties: TherapistInquirySubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TherapistInquirySubmitted(properties), options);
  }

  /**
   * Therapists Listing Presented
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Therapists%20Listing%20Presented)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. approaches)
   * @param options Amplitude event options.
   */
  therapistsListingPresented(
    properties?: TherapistsListingPresentedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TherapistsListingPresented(properties), options);
  }

  /**
   * Video Call Rated
   *
   * [View in Tracking Plan](https://data.amplitude.com/complicated-life/Its%20Complicated%20(Production)/events/main/latest/Video%20Call%20Rated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. rating)
   * @param options Amplitude event options.
   */
  videoCallRated(
    properties: VideoCallRatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VideoCallRated(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
