import { ampli, ApiKey, DefaultConfiguration } from "@/analytics/ampli";
import * as amplitude from "@amplitude/analytics-browser";
import { LogLevel } from "@amplitude/analytics-types";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

// Amplitude supports SDK deferred initialization. Events tracked before initialization
// will be dispatched after the initialization call.
//
// Load ensures that events are still captured in memory
function loadAmplitude() {
  const environment = trackingEnv();
  if (typeof window === "undefined") {
    return;
  }

  // Create an instance that will be initialized later
  const amplitudeClient = amplitude.createInstance();
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: process.env.NEXT_PUBLIC_APP_ENV === "production" ? 0.1 : 1.0,
  });
  amplitudeClient.add(sessionReplayTracking);
  return ampli.load({
    environment,
    disabled: false,
    client: {
      instance: amplitudeClient,
    },
  });
}

// Amplitude supports SDK deferred initialization. Events tracked before initialization
// will be dispatched after the initialization call.
//
// Activation ensures that events are reported, event the one captured before.
function activateAmplitude() {
  if (!ampli.client) {
    throw new Error("Amplitude must be loaded before being activated");
  }

  const environment = trackingEnv();
  const apiKey = ApiKey[environment];
  const configuration = {
    ...DefaultConfiguration,
    defaultTracking: {
      attribution: true,
      sessions: true,
      pageViews: false, // not-interested since our urls are localized
      formInteractions: false, // not-interested
      fileDownloads: false, // not-interested
    },
    cookieOptions: {
      domain: process.env.NEXT_PUBLIC_AMPLITUDE_DOMAIN_NAME,
    },
    minIdLength: 1, // Default is 5 but IC ids starts with 1 character
    logLevel: environment === "production" ? LogLevel.None : LogLevel.Verbose,
  };
  ampli.client.init(apiKey, undefined, configuration);
}

function trackingEnv(): "production" | "staging" | "development" {
  const env = process.env.NEXT_PUBLIC_AMPLITUDE_ENV;
  switch (env) {
    case "production":
    case "staging":
    case "development":
      return env;
  }
  throw new Error(`Invalid Amplitude environment: ${env}`);
}

export { activateAmplitude, loadAmplitude };
