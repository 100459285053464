import { ampli } from "@/analytics/ampli";
import * as auth from "@/analytics/analytics.auth";
import * as directory from "@/analytics/analytics.directory";
import { MyUser } from "@/api/entities/myUser";
import { withSilentTimeout } from "@/utils/PromiseUtils";
import { loadAmplitude } from "./amplitude";
import { loadGTM } from "./googletagmamager";
import { showConsentSettings } from "./usercentrics";

function load() {
  loadGTM();
  loadAmplitude();
}

function askPrivacyConsent() {
  // On first visit, usercentrics takes care of it, this is intended
  // to be used when the user wants to change heir settings later.
  showConsentSettings();
}

async function flushWithTimeout() {
  await withSilentTimeout(3000, ampli.flush().promise);
}

async function identify(user: MyUser | null) {
  if (user) {
    ampli.identify(user.analytics_user_id, {
      kind: userAnalyticsKind(user.kind),
    });
  } else {
    // Anonymous users are considered of kind "Client" for analytics
    // https://www.notion.so/Analytics-Data-Guidelines-01bb551dc59e497d95b5e5ab672232e1?pvs=4#fb24584b06ef4ae99e10e172235ae4c9
    ampli.identify(undefined, { kind: userAnalyticsKind("Client") });
  }
}

function userAnalyticsKind(userKind: MyUser["kind"]): "therapist" | "client" {
  switch (userKind) {
    case "Client":
      return "client";
    case "Therapist":
      return "therapist";
    default:
      throw new Error(`unknown user kind:${userKind}`);
  }
}

export { askPrivacyConsent, auth, directory, flushWithTimeout, identify, load };
